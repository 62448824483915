let Bankcard = function(tokenClient, serviceAddr) {
	let TokenClient = tokenClient
	let ServiceBaseAddress = serviceAddr

    this.bankcardList = function(oranizationId, success, error) {
		var url = ServiceBaseAddress + '/api/BankCard/FindByOrganization/'+ oranizationId
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.bankcardAdd = function(item, success, error) {
		var url = ServiceBaseAddress + '/api/Bankcard'
			TokenClient.Post(
				url,
				true,
				{ 'Content-Type': 'application/json' },
				item,
				function(data) {
					if (success) {
						var jsonObj = JSON.parse(data)
						success(jsonObj)
					}
				},
				error
			)
	}
	this.Edit = function (item, success, error) {
		if (item && item.id) {
			var url = ServiceBaseAddress + '/api/Bankcard';
			TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, item,
				function (data) {
					if (success) {
						var jsonObj = JSON.parse(data);
						success(jsonObj);
					}
				}, error);
		}
	}
	this.bankcardRemove = function(organizationId, success, error) {
		var url =
			ServiceBaseAddress + '/api/Bankcard/?bankCardId=' + organizationId
		TokenClient.Delete(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.getPayeeUserInfo = function(name,success, error) {
        var url = ServiceBaseAddress + '/api/WeChatPay/PayeeUserInfo/' + name
        TokenClient.Post(url, true, null, null, function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	this.getOrganizationName = function(name,success, error) {
        var url = ServiceBaseAddress + '/api/WeChatPay/PayeeOrganizationInfo/' + name
        TokenClient.Post(url, true, null, null, function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
	this.toBalanceTransfer = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/WeChatPay/BalanceTransfer'
        TokenClient.Post(
            url,
            true,
            { 'Content-Type': 'application/json' },
            item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

}

export { Bankcard }
