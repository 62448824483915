<template>
  <div>
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <el-button type="primary" size="small" @click="Add()" plain>新增</el-button>
        </div>
        <el-table :data="bankcardData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }">
          <el-table-column prop="sort" label="序号"> </el-table-column>
          <el-table-column prop="userName" label="开户人"> </el-table-column>
          <el-table-column prop="cardNo" label="开户账号" align="center"> </el-table-column>
          <el-table-column prop="bankName" label="开户行" align="center"> </el-table-column>
          <el-table-column prop="isICBC" label="是否是工行" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.isICBC?"是":"否" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="regionCode" label="收款账号地区码" align="center"> </el-table-column>
          <el-table-column prop="isfalse" label="默认" align="center"> </el-table-column>
          <el-table-column fixed="right" label="操作" align="center">
            <template slot-scope="scope">
              <span class="info optionBtn" @click="EditBtn(scope.row)">编辑</span>
              <span class="danger optionBtn" @click="RemoveBtn(scope.row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog :title="DialogTitle" :visible.sync="setDialog">
      <el-form class="dialog-form" :model="bankCardForm" ref="bankCardForm" :label-width="formLabelWidth" :rules="rules">
        <el-form-item label="开户人" prop="userName">
          <el-input v-model="bankCardForm.userName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="开户账号" prop="cardNo">
          <el-input v-model="bankCardForm.cardNo" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="开户支行" prop="bankName">
          <el-input v-model="bankCardForm.bankName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="开户支行所属省份" prop="banckProvince">
          <el-select v-model="bankCardForm.banckProvince" placeholder="请选择" @change="handleChange">
            <el-option v-for="item in RegionData" :key="item.id" :label="item.regionName" :value="item"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开户支行所属市" prop="banckCity">
          <el-select v-model="bankCardForm.banckCity" placeholder="请选择">
            <el-option v-for="item in RegionData2" :key="item.id" :label="item.regionName" :value="item.regionName"> </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="是否是工商银行" prop="isICBC">
          <el-radio-group v-model="bankCardForm.isICBC" size="mini" :disabled="bankCardForm.id">
            <el-radio-button label="true">是</el-radio-button>
            <el-radio-button label="false">否</el-radio-button>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="默认银行卡">
          <el-switch v-model="bankCardForm.isDefault"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="CloseBtn('bankCardForm')">取 消</el-button>
        <el-button type="primary" @click="SaveBtn('bankCardForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import bread from '../../../components/common/breadcrumb'
import { User } from '../../../components/HospitalDomain/User'
import { Bankcard } from '../../../components/HospitalDomain/Bankcard'
import { Region } from '../../../components/HospitalDomain/Region'
export default {
  name: 'BankCard',
  components: {
    bread,
  },
  data() {
    var region = new Region(this.TokenClient, this.Services.Authorization)
    var bankcard = new Bankcard(this.TokenClient, this.Services.Authorization)
    var user = new User(this.TokenClient, this.Services.Authorization)
    return {
      Region: region,
      RegionData: [],
      RegionData2: [],
      UserDomain: user,
      oranizationId: null,
      UserId: null,
      Bankcard: bankcard,
      bankcardData: [],
      setDialog: false,
      DialogTitle: '添加银行卡',
      bankCardForm: {
        userName: '',
        cardNo: '',
        bankName: '',
        isDefault: false,
        oranizationId: null,
        operationUserId: null,
        banckCity: null,
        banckProvince: null,
        isICBC: false,
      },
      formLabelWidth: '150px',
      rules: {
        userName: [{ required: true, message: '请输入开户人', trigger: 'blur' }],
        cardNo: [
          { required: true, message: '请输入开户账号', trigger: 'blur' },
          { pattern: /^([1-9]{1})(\d{12,18})$/, message: '请输入正确的卡号', trigger: 'blur' },
        ],
        bankName: [{ required: true, message: '请输入开户支行', trigger: 'blur' }],
        banckProvince: [{ required: true, message: '请选择开户支行所属省份', trigger: 'change' }],
        banckCity: [{ required: true, message: '请选择开户支行所属市', trigger: 'change' }],
        isICBC: [{ required: true, message: '请选择', trigger: 'blur' }],
      },
      regionId: 0,
    }
  },
  created() {
    this.getOranizationId()
  },
  mounted() {
    this.InitialRegion()
    this.getbankcard()
  },
  methods: {
    handleChange(value) {
      this.bankCardForm.banckProvince = value.regionName
      this.bankCardForm.banckCity = null
      this.regionId = value.id
      this.InitialRegion()
    },
    InitialRegion() {
      var _this = this
      _this.Region.Regions(
        _this.regionId,
        function (data) {
          if (_this.regionId) {
            _this.RegionData2 = data.data
          } else {
            _this.RegionData = data.data
          }
        },
        function (err) {
          console.log(err)
        }
      )
    },
    getOranizationId() {
      this.oranizationId = this.UserDomain.GetSessionUserInfo().organizationId
      this.UserId = this.UserDomain.GetSessionUserInfo().id
    },
    Add() {
      var _this = this
      _this.setDialog = true
      _this.DialogTitle = '添加银行卡'
      _this.bankCardForm = {
        id: 0,
        userName: '',
        cardNo: '',
        bankName: '',
        isDefault: false,
        organizationId: _this.oranizationId,
        banckCity: null,
        banckProvince: null,
        isICBC: false,
      }
    },
    EditBtn(e) {
      this.setDialog = true
      this.DialogTitle = '编辑银行卡'
      this.bankCardForm = {
        id: e.id,
        userName: e.userName,
        cardNo: e.cardNo,
        bankName: e.bankName,
        isDefault: e.isDefault,
        organizationId: this.oranizationId,
        operationUserId: this.UserId,
        banckCity: e.banckCity,
        banckProvince: e.banckProvince,
        isICBC: e.isICBC,
      }
    },
    SaveBtn(formName) {
      var _this = this
      console.log(_this.bankCardForm)
      _this.$refs[formName].validate((res) => {
        if (res) {
          if (this.bankCardForm.id > 0) {
            _this.Bankcard.Edit(
              _this.bankCardForm,
              function (data) {
                _this.$message({
                  type: 'success',
                  message: '修改成功!',
                })
                _this.setDialog = false
                _this.getbankcard()
              },
              function (err) {
                console.log(err)
              }
            )
          } else {
            _this.Bankcard.bankcardAdd(
              _this.bankCardForm,
              function (data) {
                _this.$message({
                  type: 'success',
                  message: '添加成功!',
                })
                _this.setDialog = false
                _this.getbankcard()
              },
              function (err) {
                console.log(err)
              }
            )
          }
        }
      })
    },
    RemoveBtn(e) {
      console.log(e)
      var _this = this
      _this
        .$confirm('是否删除此信息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          // type: 'warning'
        })
        .then(() => {
          _this.Bankcard.bankcardRemove(
            e.id,
            function (data) {
              _this.$message({
                type: 'success',
                message: '删除成功!',
              })
              _this.getbankcard()
            },
            function (err) {
              console.log(err)
            }
          )
        })
        .catch(() => {})
    },
    CloseBtn(formName) {
      this.setDialog = false
      this.$refs[formName].resetFields()
    },
    getbankcard() {
      var _this = this
      _this.bankcardData = []
      _this.Bankcard.bankcardList(
        this.oranizationId,
        function (data) {
          _this.bankcardData = data.data
          for (let i = 0; i < _this.bankcardData.length; i++) {
            _this.bankcardData[i].isfalse = _this.bankcardData[i].isDefault == true ? '是' : '否'
            _this.bankcardData[i].sort = i + 1
          }
        },
        function (err) {
          console.log(err)
        }
      )
    },
  },
}
</script>

<style scoped>
.dialog-form {
  width: 85%;
}
.table-top {
  margin-bottom: 15px;
}
</style>
